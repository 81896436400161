import React from 'react'
import FisherProfileImg from '../assets/images/profile_picture_vertical.jpeg'
import AboutBox from '../components/About/AboutBox'
import about from '../data/About'
import { UserWrapper, UserTitle, UserDescription, DownloadButton, UserTopic, BioTitle, BioDescription} from '../styles/indexStyle.js'
import Layout from '../components/layout'

const IndexPage = () => {
  return <Layout>
  <UserWrapper>
    <UserTitle>Ryan D. Fisher </UserTitle>
    {/* <span>Engineering</span> */}
    <UserDescription>
      <div>
        <p>
        Thanks for visiting my portfolio. 
        Feel free to look around and reach out if you have more questions 
        regarding the previous works or potential future partnerships.
        </p>
        
        <BioTitle><p>Brief Introduction:</p></BioTitle>
        <BioDescription>
        <p>
        I am a passionate aerospace engineer who graduated from the Kevin T. Crofton Department of
Aerospace and Ocean Engineering at Virginia Tech. During my time at Virginia Tech, I pursued a Master of Science in Aerospace Engineering through with a focus
in dynamics, control, and estimation. While pursuing graduate school, I worked full-time with Lockheed Martin Space. Upon completion of my <a href="http://hdl.handle.net/10919/110850">thesis</a>, I've pursued a Modeling and Simulation position
with Northop Grumman. 
My experience as a Modeling and Simulation Engineer with a demonstrated history of working in the aviation and aerospace industry has provided me excellent background in research, leadership, time management, and programming. 
I have grown a strong engineering professional background through my experiences and plan to continue so through future opportunities. 
        </p>
        </BioDescription>
        
        
{/* Download button here if you need it. */}
        {/* <DownloadButton href="https://github.com/rfish44"  download title="Resume">Download Resume</DownloadButton> */}
      </div>
      <img src={FisherProfileImg} max-width="50%" aspect-ratio="initial" alt="Fisher profile img" />
    </UserDescription>
    <UserTopic>
      {
        about.map(item => (<AboutBox key={item.id} info={item} />))
      }
    </UserTopic>
  </UserWrapper>
  </Layout>
}

export default IndexPage
