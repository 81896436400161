export default [
    {
        id: 1,
        title: "Education",
        description: "In May of 2020, I graduated from Virginia Tech with a Bachelor of Science in Aerospace Engineering with focus in dynamics, controls, and estimation. I am now further pursuing a Master's Degree in Aerospace Engineering with my thesis being on controlled UAS termination.",
        path: "/education"
    },
    {
        id: 2,
        title: "Experience",
        description: "Having experience in the aerospace industry, I've been an affective systems and diagonistics engineer at multiple corporations and facilities. These experiences have allowed me to apply my aerospace knowledge to directly assist fleets and other customer products.",
        path: "/experience"
    },
    {
        id: 3,
        title: "Projects",
        description: "I have completed many projects through my years as an undergraduate, many which were research based, others which were direct aerospace applications. Listed here are the projects that I possessed a critical role in and are most proud of.",
        path: "/projects"
    },
    {
        id: 4,
        title: "Other Resources",
        description: "Through my professional career I have come accross many helpful aerospace and software resources that have helped me succeed both in academia and the workplace; many relate to software development or aerospace applications. All resources are free and open source.",
        path: "/otherresources"
    }
];
